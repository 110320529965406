.navbar {
  background-color: #FCF9F5;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #d6d6db;
  padding: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  transition: color 0.3s ease;
  padding: 10px 20px;
  font-weight: 600;
}
.active {
  background: #F4F2EC;
}

.nav-link:hover {
  background-color: #F4F2EC;
  border-radius: 30px;
}

.brand-logo {
  height: 40px; /* Adjust height as necessary */
}

body {
  font-family: 'Manrope', sans-serif;
  font-size:16px;
}

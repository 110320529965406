/* src/components/Work.css */

.work {
    padding: 50px 0; /* Adjust padding as needed */
  }
  
  .work-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr); /* Two columns layout */
    gap: 20px;
  }
  
  .work-column {
    flex: 1;
  }
  
  .home-projects{
        display: grid;
        flex-wrap: wrap;
        max-width: 1240px;
        margin: 32px auto 85px;
        transition: all .3s ease;
        height: 100%;
        padding: 0 20px;
        grid-template-columns: repeat(2, 1fr);
        text-align: left;
    
  }

.home-project{

        overflow: hidden;
        position: relative;
        min-height: 400px;
        transition: all .2s ease;
        color: white;
    
}

.dacodes{
    background-color: #040848;
}
.viralco{
    background-color: blue;
}

.fondo{
    margin: auto;
    position: absolute;
    height: auto;
    bottom: -10%;
    left: 0;
    right: 0;
}

.homes.info {
    padding: 32px;
    flex-direction: column;
    display: flex;
    width: 100%;
    
}


  /* Adjust styles for responsive layout as needed */
  @media (max-width: 768px) {
    .work-container {
        grid-template-columns: 1fr;
    }
    .home-projects{
        flex-direction: column;
    }

  }

  a {
    color: inherit; /* Inherits the color from its parent element */
    text-decoration: none; /* Removes the underline */
}


.text-rotator {
  display: flex;
  }
  
  .paragraph-17 {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .wrapper {
    overflow: hidden;
    margin-left: 5px;
    font-weight: 800;
  }
  
  .mover {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
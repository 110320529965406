/* src/components/Card.css */

.card {
    overflow: hidden;
    transition: transform 0.3s ease;
    text-align: left;
    color: white;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-image {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 15px;
  }
  
  .card-title {
    margin: 0;
    font-size: 1.5em;
  }
  
  .card-description {
    margin: 10px 0 0;
    font-size: 1rem;
  }
  
/* src/Section1.css */

.section1 {
  padding: 50px;
  margin-bottom: 20px;
  max-width: 1200px;
  margin: 0 auto;

}

.columns {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  gap: 20px; /* Adjust the gap between columns */
  
}




@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }

}


.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: none;
  text-align: left; 
  justify-content: center;
}


.intro {
  font-size: 24px;
}

img {
  max-width: 100%;
  height: auto;
}

/* src/components/MyComponent.css */

.strikethrough-word {
  position: relative;
  display: inline-block;
}

.strikethrough-word::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 40%;
  width: 100%;
  height: 2px;
  background-color: red; 
}
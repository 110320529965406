/* src/components/Resume.css */

.resume {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    display: flex;

  }
  
  h1, h2 {
    color: #333;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    
  }
  
  h2 {
    font-size: 1.8em;
    margin-top: 30px;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  .experience-item, .education-item {
    margin-top: 20px;
  }
  
  .experience-item h3, .education-item h3 {
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  
  .skills-list {
    list-style-type: none;
    padding: 0;
  }
  
  .skills-list li {
    display: inline-block;
    background-color: #f0f0f0;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .left-section {
    width: 50%;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .right-section {
    width: 50%;
    padding: 20px;
  }
  
  .experience-item {
    margin-bottom: 20px;
  }
  
  .education-item,
  .skills-list {
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  